import React, { Suspense, lazy } from 'react';
import { Switch, Route, withRouter } from "react-router-dom";
import { Navbar } from "../components";
import PrivateRoute from "./privateRoute";
import TempRoute from "./tempRoute";

// basic auth
const Login = lazy(() => import('../components/auth/login'));
const Signup = lazy(() => import('../components/auth/signup'));
const ForgotPassword = lazy(() => import('../components/auth/forgotPassword'));

// circle invite Signup
const InviteSignup = lazy(() => import('../components/auth/invite-signup'));

// on boaring flow singup flow
const Signup_On_Boarding = lazy(() => import('../components/auth/signup_onboarding'));
const Sportbook_SyncConfirm = lazy(() => import('../components/auth/sb_syncConfirm'));
const ConfirmAccount = lazy(() => import('../components/auth/confirmAccount'));
const AccountComplete = lazy(() => import('../components/userAccount/accountComplete'));

// on boaring flow
const ReviewBetOnboarding = lazy(() => import('../components/dashboard/reviewBet/review_onboarding'));

// Account setting
const AccountInfo = lazy(() => import('../components/userAccount/accountInfo'));
const UpdatePassword = lazy(() => import('../components/userAccount/updatePassword'));
const SetAvatar = lazy(() => import('../components/userAccount/setAvatar'));
const LeaguePreferences = lazy(() => import('../components/userAccount/leaguePreferences'));
const Subscription = lazy(() => import('../components/userAccount/subscription'));
// bet creation flow
const PropBets = lazy(() => import('../components/dashboard/dashboard/propBets'));
const ReviewBet = lazy(() => import('../components/dashboard/reviewBet/review'));
const ReviewBetAdvTracking = lazy(() => import('../components/dashboard/reviewBet/reviewAdvTracking'));
const ReviewStep2 = lazy(() => import('../components/dashboard/reviewBet/reviewStep2'));

// circle
const Circle = lazy(() => import('../components/userAccount/circle'));
const CreateCircle = lazy(() => import('../components/userAccount/circle/createCircle'));
const InviteCircle = lazy(() => import('../components/userAccount/circle/inviteCircle'));
const InviteAction = lazy(() => import('../components/userAccount/circle/inviteAction'));
const CircleDetail = lazy(() => import('../components/userAccount/circle/circleDetail'));
const ContestDetails = lazy(() => import('../components/userAccount/circle/contest/contestDetails'));
const CircleLeagueChat = lazy(() => import('../components/userAccount/circle/circleChat/circleLeagueChat'));
const ContestUserbets = lazy(() => import('../components/userAccount/circle/contest/contestUserbets'));
const BetSelection = lazy(() => import('../components/userAccount/circle/betSelection/betSelection'));
const Info = lazy(() => import('../components/userAccount/circle/contest/info'));
const MyCoins = lazy(() => import('../components/userAccount/mycoins'));

// Invite in circle
const InviteCircleNonUser = lazy(() => import('../components/unauth/circle/invite'))
const CircleInfo = lazy(() => import('../components/unauth/circle/circleInfo'))
const InviteCircleLanding = lazy(() => import('../components/unauth/circle/landing'))

// my-action
const MyAction = lazy(() => import('../components/dashboard/myAction/index'));
// my stats
const MyStats = lazy(() => import('../components/dashboard/myStats'));
const CreateOldBet = lazy(() => import('../components/dashboard/myStats/historyBet'));
const SelectedBet = lazy(() => import('../components/dashboard/myStats/historyBet/selectedBet'));

// BetSync
const BetSync = lazy(() => import('../components/userAccount/betSync'));
const ApprovBet = lazy(() => import('../components/userAccount/syncBets/approvBets'));
const Syncing = lazy(() => import('../components/userAccount/syncBets/syncing'));
const ReconcileBets = lazy(() => import('../components/userAccount/syncBets/reconcileBets'));

// PrivacyANDTerms
const PrivacyANDTerms = lazy(() => import('../components/privacy'));
// depreciate
const MyAccount = lazy(() => import('../components/userAccount/index'));
const Notification = lazy(() => import('../components/dashboard/notification'));

const Routes = (props) => {

  return (
    <>
      {props.history.location.pathname === "/" || props.history.location.pathname === "/dashboard"
        ? (
          <>
            <Navbar {...props} />
          </>
        ) : (
          <></>
        )}

      <Suspense fallback={<div></div>}>

        <Switch>
          {/* <Route exact path="/" component={Dashboard} /> */}
          <Route exact path="/sign-in" component={Login} />
          <Route exact path="/sign-up" component={Signup} />
          <Route exact path="/sign-up/:email" component={Signup} />
          <Route exact path="/sign-up_on_boarding" component={Signup_On_Boarding} />
          <Route exact path="/invite-sign-up/:email/:id" component={InviteSignup} />
          <Route exact path="/forgotPassword" component={ForgotPassword} />
          <Route exact path="/policy" component={PrivacyANDTerms} />
          <Route exact path="/invite-landing" component={InviteCircleLanding} />
          <Route exact path="/invite-landing-info" component={CircleInfo} />
          <Route exact path="/circle-invite" component={InviteCircleNonUser} />
          <Route exact path="/notification" component={Notification} />
          <Route exact path="/review_on_board" component={ReviewBetOnboarding} />

          <TempRoute exact path="/sportbook_SyncConfirm" component={Sportbook_SyncConfirm} />
          <TempRoute exact path="/confirmAccount" component={ConfirmAccount} />

          <PrivateRoute exact path="/accountComplete" component={AccountComplete} />
          <PrivateRoute exact path="/my-action/" component={MyAction} />
          <PrivateRoute exact path="/my-action/:tab" component={MyAction} />
          <PrivateRoute exact path="/my-stats" component={MyStats} />
          <PrivateRoute exact path="/my-stats/:tab" component={MyStats} />
          <PrivateRoute exact path="/selected-Bet" component={SelectedBet} />

          <PrivateRoute exact path="/reviewBet" component={ReviewBet} />
          <PrivateRoute exact path="/advReviewBet" component={ReviewBetAdvTracking} />
          <PrivateRoute exact path="/placeReviewBet" component={ReviewStep2} />
          <Route exact path="/propBets/:tab" component={PropBets} />

          <PrivateRoute exact path="/myAccount" component={MyAccount} />
          <PrivateRoute exact path="/circle" component={Circle} />
          <PrivateRoute exact path="/circle/bet-select" component={BetSelection} />
          <PrivateRoute exact path="/circle/:tab" component={Circle} />
          <PrivateRoute exact path="/circle-detail/contest-details" component={ContestDetails} />
          <PrivateRoute exact path="/contest-details/info" component={Info} />
          <PrivateRoute exact path="/contest-details/user-bets" component={ContestUserbets} />



          <PrivateRoute exact path="/leaguePreferences" component={LeaguePreferences} />
          <PrivateRoute exact path="/accountInfo" component={AccountInfo} />
          <PrivateRoute exact path="/changePassword" component={UpdatePassword} />
          <PrivateRoute exact path="/circle-detail" component={CircleDetail} />
          <PrivateRoute exact path="/circle-detail/:tab" component={CircleDetail} />
          <PrivateRoute exact path="/create-circle" component={CreateCircle} />
          <PrivateRoute exact path="/invite-action" component={InviteAction} />
          <PrivateRoute exact path="/invite-circle" component={InviteCircle} />
          <PrivateRoute exact path="/circle-chat" component={CircleLeagueChat} />
          <PrivateRoute exact path="/circle-chat/:tab" component={CircleLeagueChat} />

          <PrivateRoute exact path="/betSync" component={BetSync} />
          <PrivateRoute exact path="/betSync/:tab" component={BetSync} />
          <PrivateRoute exact path="/approve-bets" component={ApprovBet} />
          <PrivateRoute exact path="/reconcileBets" component={ReconcileBets} />
          <PrivateRoute exact path="/syncing" component={Syncing} />
          <PrivateRoute exact path="/createOldBet" component={CreateOldBet} />
          <PrivateRoute exact path="/subscription" component={Subscription} />
          <PrivateRoute exact path="/subscription/:tab" component={Subscription} />
          <PrivateRoute exact path="/changeAvatar" component={SetAvatar} />

          <PrivateRoute exact path="/myCoins" component={MyCoins} />




          {/* <Redirect from="/" to="/" /> */}
        </Switch>
      </Suspense>
    </>
  );
};

export default withRouter(Routes)
