import React, { useEffect, useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { _getGames, _getLeagues, _getSportsBook, _getUserSetting, _getNewUserNotification, _getAccessToken } from "../../services";
import loaderGIF from "../../img/newLoader.gif";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import localForage from "localforage";
import Dashboard from "../dashboard/dashboard/dashboard";
import Sidebar from "./sidebar";
import { useStore } from "../../store";
import { shallow } from "zustand/shallow"
import { PoppingText } from "../dashboard/helper"
import selectGame from "../../img/selectGame.png"
import { ON_BOARDING } from "../../constants"
const Navbar = (props) => {
  const { showOnboardingNotifications, setUserData, setShowOnboardingNotifications, setToken, token,tempUser,setSportBooks } = useStore(store => ({
    showOnboardingNotifications: store?.showOnboardingNotifications,
    setUserData: store?.setUserData,
    setShowOnboardingNotifications: store?.setShowOnboardingNotifications,
    setToken: store?.setToken,
    token: store?.token,
    tempUser: store?.tempUser,
    setSportBooks:store?.setSportBooks
  }), shallow);

  // for user
  const [isUserCookie, setUserCookie] = useState(false);
  const [email, setEmail] = useState();
  // for menu
  const [sideBar, setSitebar] = useState(false);
  const [leagues, setLeagues] = useState([]);
  const [currentLeague, setCurrentLeague] = useState({})
  const [loader, setLoader] = useState(false);
  const [innerLoader, setInnerLoader] = useState(true);
  const [navTabNumber, setNavBar] = useState(0);
  const [edgeValue, setEdgeValue] = useState(0);
  const [games, setGames] = useState([]);
  const [showSideMenu, setShowSideMenu] = useState(false);
  // set tab theme
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: "100%",
      backgroundColor: theme.palette.background.paper,
    },
  }));

  useEffect(() => {
    if(tempUser?.userId){
      if (localStorage.getItem("step") == "signup")
        props.history.push("/sign-up_on_boarding")
      else if (localStorage.getItem("step") == "link")
        props.history.push("/sportbook_SyncConfirm");
      else if (localStorage.getItem("step") == "confirm")
        props.history.push("/confirmAccount")
    }
    if (localStorage.getItem("step") == "complete")
      props.history.push("/accountComplete");
  }, [])
  useEffect(async () => {
    setLoader(true);
    // get userEmail if it's login
    const userEmail = await localForage.getItem("email");
    const isJoined = localStorage.getItem("isJoined"); //used as a cookie never removed
    if (!userEmail?.includes("@") && isJoined) {
      props.history.push("/sign-in");
    }
    else {
      if (userEmail?.includes("@")) {
        const res = await _getUserSetting(userEmail)
        if (res?.success && res?.data) {
          setUserData(res.data[0]);
          if (!token) {
            const token = await _getAccessToken(userEmail?.toLowerCase())
            let userToken = token?.data?.data?.access_token;
            setToken(userToken);
          }
        }

      }
      checkmktSource(isJoined, userEmail);
      const isOnBoardingComplete = localStorage.getItem("onBoardingComplete");  //on boarding message
      if (isOnBoardingComplete != "true" && useStore?.getState()?.showOnboardingNotifications == "") {
        console.log("Why its here????", isOnBoardingComplete, useStore?.getState()?.showOnboardingNotifications);
        setShowOnboardingNotifications(ON_BOARDING[0])
        // const res = await _getNewUserNotification();
        // console.log({res});
        // localStorage.setItem("notification", JSON.stringify(res?.data))
        // props.history.push("/notification");
      }

      let navSelected = localStorage.getItem("navTab") ?? 0;
      // get all the headers
      let leagues = localStorage.getItem("headerTabs");
      // filter not hidden leagues
      if (leagues) {
        leagues = JSON.parse(leagues);
        leagues = leagues?.filter(league => league.hidden == false);
      };

      if (navSelected == 0 || (navSelected && navSelected >= 0)) {
        if (leagues?.length == parseInt(navSelected))
          navSelected = 0;
        else if (leagues?.length < parseInt(navSelected))
          navSelected = 0;
        setNavBar(parseInt(navSelected));
      }
      setUserCookie(isJoined);
      setEmail(userEmail);
      // get sportsbook
      const sBooks = await _getSportsBook()
      setSportBooks(sBooks?.data)
      
      if (!leagues) {
        leagues = await _getLeagues(userEmail);
        leagues = leagues.filter(league => league.hidden == false);
        localStorage.setItem("headerTabs", JSON.stringify(leagues));
      } else {
        _getLeagues(userEmail).then((data) => {
          let leaguesData = data.filter(league => league.hidden == false);
          setLeagues(leaguesData);
          if (userEmail) setEdgeValue(Math.round(leaguesData[navSelected].edge || 0));
          localStorage.setItem("headerTabs", JSON.stringify(leaguesData));
        });
      }
      setLeagues(leagues);
      if (userEmail) setEdgeValue(Math.round(leagues[navSelected].edge || 0));
      localStorage.setItem("lastLeague", leagues[navSelected].league);
      setCurrentLeague(leagues[navSelected])
      setLoader(false);

      await getGames(leagues[navSelected].sportId, userEmail);
      setInnerLoader(false);
    }

  }, []);

  console.log({ showOnboardingNotifications });

  const checkmktSource = (isJoined, userEmail) => {
    const queryParams = new URLSearchParams(window.location.search)
    const mktSource = queryParams.get("mktSource");
    const screenName = queryParams.get("screenName");
    if (!userEmail && (mktSource || screenName)) {
      localStorage.setItem("mktSource", mktSource);
      localStorage.setItem("mktSourceScreenName", screenName);
    }
  }

  const getGames = async (sportId, userEmail) => {
    let games;
    if (userEmail) {
      games = await _getGames(sportId, userEmail);
      setGames(games?.data);
    }
    else {
      games = await _getGames(sportId, userEmail);
      setGames(games?.data);
    }
    return games;
  };

  const handleSidebar = (e) => {
    e.preventDefault();
    setSitebar(!sideBar);
    setShowSideMenu(!showSideMenu);
  };

  const ToLogin = (e, history) => {
    e.preventDefault();
    history.push("/sign-in");
  };

  const ToSignup = (e, history) => {
    e.preventDefault();
    history.push("/sign-up");
  };

  const classes = useStyles();
  // nav bar value changed
  const handleChange = async (event, newValue) => {
    event.preventDefault();
    if (!leagues[newValue]?.active && showOnboardingNotifications == ON_BOARDING[1])
      return
    let gamesData;
    if (navTabNumber !== newValue) {
      setInnerLoader(true);
      const sportId = leagues[newValue].sportId;
      localStorage.setItem("navTab", newValue);
      setNavBar(newValue);
      gamesData = await getGames(sportId, email);
      setCurrentLeague(leagues[newValue])
      localStorage.setItem("lastLeague", leagues[newValue].league);
      setEdgeValue(Math.round(leagues[newValue].edge || 0));
      setInnerLoader(false);
    }

    if (useStore?.getState()?.showOnboardingNotifications == ON_BOARDING[1] && (gamesData?.data?.length || (navTabNumber == newValue && games?.length))) {
      setShowOnboardingNotifications(ON_BOARDING[2])
      setTimeout(() => {
        if (useStore?.getState()?.showOnboardingNotifications == ON_BOARDING[2])
          setShowOnboardingNotifications(ON_BOARDING[3])
      }, 4000)
    }
  };


  return (
    <>
      {!loader ? (
        <>
          <header className="header">
            {showOnboardingNotifications == ON_BOARDING[1] && <div className="onBoarding seques2Header">
            </div>}
            {showOnboardingNotifications == ON_BOARDING[2] && <div className="onBoarding seques3Header">
            </div>}
            {showOnboardingNotifications == ON_BOARDING[3] && <>
              <div className="onBoarding seques4Header">
              </div>
              <div className="pickYourBet">
                <span className="highlight">Pick your bet</span>
                <img src={selectGame} width={"18px"} />
              </div>
            </>}
            <div className="navContainer">
              {/* menu open button */}
              {localStorage.getItem("onBoardingComplete") && <button
                onClick={handleSidebar}
                className={`navToggle ${sideBar ? "open" : null}`}
              >
                <span />
                <span />
                <span />
              </button>}
              {/* menu */}
              <div
                onClick={handleSidebar}
                style={{ margin: "0px !importanat" }}
                className={`overlay ${sideBar ? "open" : ""}`}
              />
              {/* <div className="row" style={{ width: "90%" }}> */}
              <span className="navH1" style={{ margin: "0 auto" }}>
                SELECT BETS
              </span>
              <span className="navH1" style={{ order: "2" }}>
                {/* isUserCookie */}
                {(email || [ON_BOARDING[2], ON_BOARDING[3]]?.includes(showOnboardingNotifications)) ? (
                  <span className="join">{showOnboardingNotifications == ON_BOARDING[2] ? 50 : edgeValue}</span>
                ) : isUserCookie ? (
                  <span
                    className="join"
                    onClick={(e) => ToLogin(e, props.history)}
                  >
                    Sign in
                  </span>

                ) : (
                  <span
                    className="join"
                    onClick={(e) => ToSignup(e, props.history)}
                  >
                    Join
                  </span>
                )}
              </span>

              <Sidebar
                email={email}
                sideBar={sideBar}
                handleSidebar={handleSidebar}
                history={props.history}
                setSitebar={setSitebar}
                showSideMenu={showSideMenu}
                setShowSideMenu={setShowSideMenu}
                showOnboardingNotifications={showOnboardingNotifications}
              />
            </div>
            {![ON_BOARDING[0]]?.includes(showOnboardingNotifications) && <div className={classes.root} style={{ borderBottom: "1px solid #cccccc" }}>
              <AppBar position="static" color="default">
                <Tabs
                  TabIndicatorProps={{ style: { backgroundColor: "black" } }}
                  value={navTabNumber}
                  onChange={handleChange}
                  indicatorColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="Games list"
                >
                  {leagues.map((tabInfo, index) => (
                    !tabInfo.hidden &&
                    <Tab
                      label={tabInfo.league}
                      id={`simple-tab-${index}`}
                      key={tabInfo.sportId}
                      className={tabInfo.active ? "active" : "Inactive"}
                      aria-controls={`simple-tabpanel-${index}`}
                    />
                  ))}
                </Tabs>
              </AppBar>
            </div>}
            {showOnboardingNotifications == ON_BOARDING[1] && <div className="onBoarding seques2Header2" style={{ zIndex: 111 }}>
              <div className="container" style={{ marginTop: "10px" }}>
                <span className="text-center fs-23">
                  <PoppingText text={"First choose the league"} />
                </span>
              </div>
            </div>}
          </header>
          <div className="wrapper"></div>
          {!innerLoader ? (
            <Dashboard games={games} key={games} getGames={getGames} history={props.history} currentLeague={currentLeague} user={email} />
          ) : (
            <div style={{ textAlign: "center", marginTop: "40%" }}>
              <img
                src={loaderGIF}
                style={{ margin: "10px auto", display: "block" }}
              />
            </div>
          )}
        </>
      ) : (
        <div style={{ textAlign: "center", marginTop: "40%" }}>
          <img
            src={loaderGIF}
            style={{ margin: "10px auto", display: "block" }}
          />
        </div>
      )}
    </>
  );
};

export default Navbar;
